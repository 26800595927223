import React from 'react';
import { graphql } from 'gatsby';
import ToolboxLayout from 'layouts/toolboxLayout';
import Hero from 'components/Tools/Hero';
import { BCGraderForm } from 'components/Tools/BusinessGrader/Form';
import DescriptionBlock from 'components/Tools/DescriptionBlock';
import STTestimonialCarousel from 'components/Sections/TestimonialCarousel/STTestimonialCarousel';
import { createToolsBreadcrumbs } from '../utils/createToolsBreadcrumbs';
import BookmarkContext from 'utils/BookmarkContext';

const GraderCalculatorPage = (props) => {
  const title = 'Service Business Grader';
  const data = props.data.contentfulTool;
  const breadcrumbs = createToolsBreadcrumbs(title);
  const photoImg = props.data.contentfulImage;

  const allFeedbacks = [
    {
      title:
        '“ServiceTitan grew Bryant Electric’s revenue by 109%. It’s changed our business and it’s changed our life”',
      description: {
        description: '',
      },
      photo: photoImg.image,
      clientName: 'Jeff Bryant of Bryant Electric',
      companyName: 'ServiceTitan customer for 3 years',
    },
  ];

  const bookmarkData = {
    title: data.internalName,
    image: data?.cardImage?.image,
    subTitle: 'Tool',
    url: props.path,
    type: 'tool',
  };

  return (
    <BookmarkContext.Provider value={bookmarkData}>
      <ToolboxLayout breadcrumbs={breadcrumbs} contentfulSeo={data.seo} layout="toolbox" {...props}>
        <Hero data={data.hero} />
        <BCGraderForm marketoFormId={2247} width="100%" />
        {data.description && <DescriptionBlock data={data.description} />}
        <STTestimonialCarousel
          type="descriptiononly"
          background="var(--skyline-1)"
          feedbacks={allFeedbacks}
        />
      </ToolboxLayout>
    </BookmarkContext.Provider>
  );
};

export default GraderCalculatorPage;

export const GraderCalculatorPageQuery = graphql`
  query GraderCalculatorPageQuery {
    contentfulTool(internalName: { eq: "Service Business Grader" }) {
      ...Tool
    }
    contentfulImage(contentful_id: { eq: "1K4u6yyVbtkWXnOoSVfc5Q" }) {
      internalName
      image {
        file {
          url
        }
      }
    }
  }
`;
