import { navigate } from 'gatsby';
import Cookies from 'js-cookie';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import FormLabelWithField from 'components/Core/FormLabelWithField';
import TextField from 'components/Core/TextField';
import Button from 'components/Core/Button';
import Select from 'components/Core/Select';
import { init, saveForm } from 'services/marketoService';
import useIndustries from 'hooks/useIndustries';
import { getFormValid } from 'utils/formUtils';
import { GraderFormContainer, FormStep } from '../styles';
import { FormContainer } from '../../styles';

const GraderForm = ({
  width,
  height,
  background,
  titleColor,
  title,
  buttonTitle,
  footer,
  shadow,
  marketoId,
  marketoURL,
  marketoFormId,
  setTitle,
  setDescription,
  ...otherProps
}) => {
  const { industryList: dropdownIndustry, otherIndustryList: dropdownOtherIndustry } =
    useIndustries();
  const [isOtherIndustryShow, setOtherIndustryShow] = useState(false);
  const [formSubmissions, setFormSubmissions] = useState(true);

  const formik = useFormik({
    initialValues: {
      Email: Cookies.get('email') || '',
      FirstName: Cookies.get('firstName') || '',
      LastName: Cookies.get('lastName') || '',
      Company: Cookies.get('company') || '',
      Phone: Cookies.get('mobilePhone') || '',
      technicians: '',
      industry: '',
      otherIndustry: '',
      postalCode: '',
      averageTicketSize: '',
      completedJobs: '',
    },
    validationSchema: Yup.object().shape({
      Email: Yup.string().email('Email is invalid').required('Email is required'),
      FirstName: Yup.string().trim().required('First Name is required'),
      LastName: Yup.string().trim().required('Last Name is required'),
      Company: Yup.string().trim().required('Company is required'),
      technicians: Yup.string().required('Technicians is required'),
      industry: Yup.string().required('Industry is invalid'),
      otherIndustry: Yup.string().when('industry', {
        is: 'Other',
        then: Yup.string().required('Other Industry is invalid'),
      }),
      postalCode: Yup.string().required('Postal Code is invalid'),
      Phone: Yup.string()
        .required('Phone Number is required')
        .min(14, 'Phone Number must be 10 numbers')
        .nullable(),
      averageTicketSize: Yup.string().required('Average Ticket size is invalid'),
      completedJobs: Yup.string().required('Completed Job is invalid'),
    }),
    onSubmit: (values) => {
      onSubmitStepOne();
    },
  });

  const nameType = {
    Email: '* Email address',
    FirstName: '* First Name',
    LastName: '* Last Name',
    Phone: '* Phone Number',
    Company: '* Company',
    technicians: '* How many technicians do you have?',
    industry: '* Industry',
    otherIndustry: '* Other industry',
    postalCode: '* Postal code',
    averageTicketSize: '* Average ticket size ($)',
    completedJobs: '* How many jobs have you completed in the last 30 days?',
  };

  useEffect(() => {
    init(marketoFormId);
  }, []);

  const onHandleBlur = (id, newValue) => {
    formik.setFieldTouched(id);
    formik.setFieldValue(id, newValue);
  };

  const onHandleChange = (id, newValue) => {
    formik.setFieldValue(id, newValue);

    if (id === 'industry') {
      setOtherIndustryShow(newValue === 'Other');
    }
  };

  const fetchMarketoData = async (data, step) => {
    try {
      await saveForm({
        ...data,
        marketoFormId,
        step,
      });
    } catch (e) {}

    navigate('/tools/service-business-grader/2', { state: { values: data } });
  };

  const onSubmitStepOne = () => {
    const values = { ...formik.values };

    dataLayer.push({
      event: 'calculator',
      calculator_type: 'Business Grader Form',
      first_name: values.FirstName,
      last_name: values.LastName,
      phone_number: values.Phone,
      email: values.Email,
      no_of_technicians: values.technicians,
      industry: values.industry,
      other_industry: values.industry === 'Other' ? values.otherIndustry : '',
      average_ticket_size: values.averageTicketSize,
      completed_jobs: values.completedJobs,
      utm_source: values.utm_source,
      utm_medium: values.utm_medium,
      utm_campaign: values.utm_campaign,
      utm_content: values.utm_content,
      utm_term: values.utm_term,
      utm_promo: values.utm_promo,
      gclid: values.gclid,
      creative: values.creative,
      keyword: values.keyword,
      matchtype: values.matchtype,
    });

    Cookies.set('firstName', values.FirstName);
    Cookies.set('lastName', values.LastName);
    Cookies.set('mobilePhone', values.Phone);
    Cookies.set('email', values.Email);
    Cookies.set('company', values.Company);

    fetchMarketoData(values, 1);

    setFormSubmissions(!formSubmissions);
  };

  const formValid = getFormValid({ ...formik });

  return (
    <FormContainer {...otherProps}>
      <div className="section-tool-page-form--wrapper">
        <div className="section-tool-page-form--container">
          <GraderFormContainer
            width={width}
            height={height}
            background={background}
            titleColor={titleColor}
            shadow={shadow}
            {...otherProps}
          >
            <p className="signup-form--description">* All fields required</p>
            <div className="signup-form--form" data-position="business grader">
              <FormStep>
                <FormLabelWithField
                  label="First Name"
                  formControlProps={{
                    sx: { display: 'block' },
                  }}
                  isRequired
                >
                  <TextField
                    id="FirstName"
                    type="text"
                    values={formik.values}
                    formValid={formValid}
                    handleBlur={onHandleBlur}
                    handleChange={onHandleChange}
                  />
                </FormLabelWithField>

                <FormLabelWithField
                  label="Last Name"
                  formControlProps={{
                    sx: { display: 'block' },
                  }}
                  isRequired
                >
                  <TextField
                    id="LastName"
                    type="text"
                    values={formik.values}
                    formValid={formValid}
                    handleBlur={onHandleBlur}
                    handleChange={onHandleChange}
                  />
                </FormLabelWithField>

                <FormLabelWithField
                  label="Phone Number"
                  formControlProps={{
                    sx: { display: 'block' },
                  }}
                  isRequired
                >
                  <TextField
                    id="Phone"
                    type="telephone"
                    format="(###) ###-####"
                    values={formik.values}
                    formValid={formValid}
                    handleBlur={onHandleBlur}
                    handleChange={onHandleChange}
                  />
                </FormLabelWithField>

                <FormLabelWithField
                  label="Company name"
                  formControlProps={{
                    sx: { display: 'block' },
                  }}
                  isRequired
                >
                  <TextField
                    id="Company"
                    type="text"
                    values={formik.values}
                    formValid={formValid}
                    handleBlur={onHandleBlur}
                    handleChange={onHandleChange}
                  />
                </FormLabelWithField>

                <FormLabelWithField
                  label="Email address"
                  formControlProps={{
                    sx: { display: 'block' },
                  }}
                  isRequired
                >
                  <TextField
                    id="Email"
                    type="text"
                    values={formik.values}
                    formValid={formValid}
                    handleBlur={onHandleBlur}
                    handleChange={onHandleChange}
                  />
                </FormLabelWithField>

                <FormLabelWithField
                  label="How many technicians do you have?"
                  formControlProps={{
                    sx: { display: 'block' },
                  }}
                  isRequired
                >
                  <TextField
                    id="technicians"
                    type="number"
                    values={formik.values}
                    formValid={formValid}
                    handleBlur={onHandleBlur}
                    handleChange={onHandleChange}
                  />
                </FormLabelWithField>
                <FormLabelWithField
                  label={nameType.industry}
                  formControlProps={{
                    sx: { display: 'block' },
                  }}
                >
                  <Select
                    id="industry"
                    formValid={formValid}
                    values={formik.values}
                    placeholder="Select industry..."
                    options={dropdownIndustry}
                    handleChange={(id, value) => onHandleChange(id, value)}
                  />
                </FormLabelWithField>

                <FormLabelWithField
                  label={nameType.otherIndustry}
                  formControlProps={{
                    className: isOtherIndustryShow ? '' : 'hide-form-item',
                    sx: { display: 'block' },
                  }}
                >
                  <Select
                    id="otherIndustry"
                    formValid={formValid}
                    values={formik.values}
                    placeholder="Select other industry..."
                    options={dropdownOtherIndustry}
                    handleChange={(id, value) => onHandleChange(id, value)}
                  />
                </FormLabelWithField>

                <FormLabelWithField
                  label="Postal code"
                  formControlProps={{
                    sx: { display: 'block' },
                  }}
                  isRequired
                >
                  <TextField
                    id="postalCode"
                    type="number"
                    values={formik.values}
                    formValid={formValid}
                    handleBlur={onHandleBlur}
                    handleChange={onHandleChange}
                  />
                </FormLabelWithField>
                <FormLabelWithField
                  label="Average ticket size ($)"
                  formControlProps={{
                    sx: { display: 'block' },
                  }}
                  isRequired
                >
                  <TextField
                    id="averageTicketSize"
                    type="currency"
                    values={formik.values}
                    formValid={formValid}
                    handleBlur={onHandleBlur}
                    handleChange={onHandleChange}
                  />
                </FormLabelWithField>
                <FormLabelWithField
                  label="How many jobs have you completed in the last 30 days?"
                  formControlProps={{
                    sx: { display: 'block' },
                  }}
                  isRequired
                >
                  <TextField
                    id="completedJobs"
                    type="number"
                    values={formik.values}
                    formValid={formValid}
                    handleBlur={onHandleBlur}
                    handleChange={onHandleChange}
                  />
                </FormLabelWithField>

                <Button
                  data-position="Form - Next CTA"
                  className="signup-form--submit-button"
                  type="primary"
                  width="100%"
                  shadow={false}
                  onClick={() => formik.submitForm()}
                >
                  {buttonTitle}
                </Button>

                <div className="form-progress">
                  <div className="form-step active"></div>
                  <div className="form-step"></div>
                  <div className="form-step"></div>
                </div>
              </FormStep>
            </div>
          </GraderFormContainer>
        </div>
      </div>
    </FormContainer>
  );
};

GraderForm.defaultProps = {
  background: 'var(--white)',
  buttonTitle: 'Next',
  footer: '',
  shadow: true,
};

export const BCGraderForm = GraderForm;
